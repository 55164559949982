import logo from './logo.svg';
import './App.css';
import HeaderMenu from './HeaderMenu';
import AboutUs from './AboutUs';
import OurServiice from './OurService';
import ServiceGallery from './ServiceGallery';
import ContractUs from './ContractUs';
import Gallery from './Gallery';
import OurPartner from './OurPartner';
import Footer from './Footer';
import { Row, Col } from 'antd';

function App() {
  return (
    <div style={{ width: "100%" }}>
      <HeaderMenu />
      <Row justify='center' style={{ width: "100%" }}>
        <Col xxs={24} xs={24} sm={24} md={24} lg={22} xl={18}>
          <AboutUs />
          <OurServiice />
          <ServiceGallery />
          <ContractUs />
          <Gallery />
          <OurPartner />
        </Col>
      </Row>
      <Footer />
    </div>
  );
}

export default App;
