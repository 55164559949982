import { Row, Col, Divider, Image } from 'antd';
import { useState, useEffect, useRef, Suspense } from 'react';
import 'antd/dist/antd.css';
import './AboutUs.css';
import { Button } from 'antd';
import { gsap, ease } from 'gsap';


function AboutUs() {
    const decoRef = useRef();
    useEffect(() => {
        gsap.to(decoRef.current, {
            duration: 1.5,
            y: "10",
            yoyo: true,
            repeat: -1,
            ease: "sine.inOut"
        });
        gsap.to(decoRef.current, {
            duration: 1.3,
            x: "8",
            yoyo: true,
            repeat: -1,
            ease: "sine.inOut"
        });
    }, []);

    return (
        <div id="section_aboutus" style={{ margin: "40px 24px" }}>
            <Suspense fallback={null}>
                <Row style={{ width: "100%" }} justify="center">
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ display: "inline-block", marginBottom: "15px" }}>

                            <div className='AboutUs-Header' style={{ display: "inline-block", width: "100%" }}>ABOUT US</div>
                            <div className='AboutUs-SubHeader' style={{ display: "inline-block", width: "100%", margin: "24px 0" }} >WE BRING YOUR IDEAS TO LIFE</div>
                            <div className='AboutUs-Body' style={{ display: "inline-block", width: "100%" }}>PushCodex is a team specialized in software and application development. Our company is based in Bangkok, Thailand. Our main focus is in the area of interactive and innovative multimedia solutions. We offer a wide range of advanced solutions for various kinds of multimedia related applications such as digital signage management, interactive directory, interactive kiosk, etc. Our company principal is to understand the customer needs and expectations. Each industry and business have different challenges. Therefore, we have been continuously developing new solutions to meet the dynamic change of evolving business landscape.</div>

                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={10} style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px" }}>
                        <img ref={decoRef} src="./images/aboutus.png" style={{ maxWidth: "calc(min(100%, 500px))", objectFit: "contain" }} />
                    </Col>
                </Row>
            </Suspense>
        </div>
    )
}

export default AboutUs;