import { Row, Col, Divider, Image } from 'antd';
import { useState, useEffect, useRef } from 'react';
import 'antd/dist/antd.css';
import './OurService.css';
import { Button, Modal } from 'antd';
import { Player } from "video-react";
import "video-react/dist/video-react.css";

const serviceData = [
    {
        id: 1,
        cover: "./images/ourservice_content_01.png",
        subtitle: "Take control of multiple screens with ease",
        title: "Digital Signage",
        content: "Our cloud-based digital signage solution offers a state-of-the-art digital signage platform that allow users to design, manage and maintain their digital content on all digital signage displays."
    },
    {
        id: 2,
        cover: "./images/ourservice_content_02.png",
        subtitle: "Provide visitors with interactive experience",
        title: "Interactive Directory",
        content: "We are experts in the design and development of interactive solution for retail sector. Our Interactive program allows users to interact with the digital content to enhance their experience."
    },
    {
        id: 3,
        cover: "./images/ourservice_content_03.png",
        subtitle: "Personalize your product",
        title: "Creative Content",
        content: "To complete our multimedia and digital signage solutions, Our team of design experts will work with clients to create digital content based on CI graphic materials and design directions."
    }
]
// const serviceData = [
//     {
//         id: 1,
//         cover: "./images/ourservice_content_01.png",
//         subtitle: "Show location shop, info, and promptions",
//         title: "Interactive Solution",
//         content: "Our cloud-based digital signage solution offers a state-of-the-art digital signage platform that allow users to design, manage and maintain their digital content on all digital signage displays."
//     },
//     {
//         id: 2,
//         cover: "./images/ourservice_content_02.png",
//         subtitle: "Reach wider shoppers inside",
//         title: "Creative Content",
//         content: "We are an expert in the design and development of interactive solution for retail sector. Our Interactive program allows users to interact with the digital content to enhances their experience."
//     },
//     {
//         id: 3,
//         cover: "./images/ourservice_content_03.png",
//         subtitle: "Engage Promption to target audience",
//         title: "Counting Solution",
//         content: "To complete our multimedia and digital signage solutions, Our team of design experts will work with client to create digital content based on CI graphic material and design direction."
//     }
// ]

const ServicePreviewModule = ({ cover, subtitle, title, content }) => {
    return (
        <div style={{ height: "100%", display: "flex", flexDirection: "column", padding: "5px 0", marginBottom: "30px" }}>
            <div style={{ flex: 10, overflow: "hidden" }}>
                <img src={cover} style={{ objectFit: "cover", width: "100%", height: "100%" }} />
            </div>
            <div style={{ padding: "0 12px" }}>

                <div className='SubHeader' style={{ flex: 1, display: "flex", paddingTop: "20px" }}>
                    {subtitle}
                </div>
                <div className='Header' style={{ display: "flex", margin: "0 0 10px 0" }}>
                    {title.toLocaleUpperCase()}
                </div>
                <div className='partialcontent' style={{ marginBottom: "14px" }}>
                    {content}
                </div>
                {/* <div className='Content' style={{ width: "100%", wordWrap: "break-word", textAlign: "justify", flex: 0.5 }}>
                    <Button>See More</Button> 
                </div> */}
            </div>

        </div >
    )
}

function OurServiice() {

    const [isLarge, setIsLarge] = useState(true);
    const [showVideo, setShowVideo] = useState(false);
    const [windowsWidth, setWindowsWidth] = useState(1920);

    const playerRef = useRef(null);

    const stopVideo = () => {
        if (playerRef.current) {
            playerRef.current.pause();
            playerRef.current.seek(0);
        }
    }
    const startVideo = () => {
        if (playerRef.current) {
            playerRef.current.seek(0);
            playerRef.current.play();
        }
    }
    const onResize = () => {
        setIsLarge(window.innerWidth > 768);
        setWindowsWidth(window.innerWidth)
    }

    useEffect(() => {

        window.addEventListener("resize", onResize)
        onResize();
    }, [])

    return (
        <div id="section_service" style={{ margin: "30px 0px" }}>
            {/* <Row>
                <Col>
                    <div className='container'>
                        <img src="./images/ourservice_btn.png" />
                        <div className='HeaderBtn'>SHOPPING MALL DIRECTORY</div>
                        <div className='BodyBtn'>Upscale shopping is made easy with our online directory. Get more information on any store with a single click</div>
                    </div>
                </Col>
            </Row> */}
            <Row gutter={12} style={{ height: isLarge ? "720px" : undefined, marginBottom: "30px" }} align="bottom" >
                <Col xs={0} sm={0} md={6} lg={6} xl={6} style={{ width: "100%", height: "100%" }}>
                    <div style={{ width: "100%", height: "100%", position: "relative" }} >

                        <img src="./images/ourservice_01.png" style={{ objecttFit: "cover", width: "100%", height: "100%" }} />

                        <div className='Buttom'>
                            PRODUCT & SERVICE
                        </div>
                    </div>
                </Col >
                <Col xs={24} sm={24} md={0} lg={0} xl={0} style={{ width: "100%", height: "100%", marginTop: "60px" }}>
                    <div style={{ width: "100%", height: "100%", position: "relative" }} >
                        <div className='Buttom'>
                            PRODUCT & SERVICE
                        </div>
                    </div>
                </Col >

                <Col xs={0} sm={0} md={18} lg={18} xl={18} style={{ width: "100%", height: "100%", cursor: "pointer" }}>
                    <Row style={{ height: "200px" }} onClick={() => { startVideo(); setShowVideo(true) }}>
                        <div className='container' style={{ width: "100%", height: "100%", position: "relative" }}>
                            <div style={{ width: "120%", height: "100%", display: "flex", alignItems: "end", position: "absolute", right: "0px" }}>
                                <img src="./images/ourservice_btn.png" style={{
                                    width: "100%", height: "100%", objectFit: "cover",
                                    position: "absolute",
                                    right: 0
                                }} />
                            </div>
                            <div style={{ width: "120%", maxWidth: `${windowsWidth}px`, height: "100%", display: "flex", alignItems: "end", position: "absolute", right: 0 }}>
                                <div style={{ width: "120%", height: "100%", position: "relative" }}>
                                    <div className='OurServiceHeaderBtn'>LED DISPLAY DESIGN SOLUTION</div>
                                    <div className='OurServiceBodyBtn'>PushCodex provides a diversity of LED display technology for various multimedia applications.</div>
                                </div>
                            </div>
                        </div>
                    </Row>

                    <Row style={{ paddingTop: "6px" }} gutter={12}>

                        {
                            serviceData.map((data, index) => {
                                return (
                                    <Col key={`service_${index}`} xs={24} sm={8} md={8} lg={8} xl={8} style={{ width: "100%", height: "520px", backgroundColor: "white" }}>
                                        <ServicePreviewModule {...data}></ServicePreviewModule>
                                    </Col>
                                )
                            })
                        }

                    </Row>
                </Col>
                <Col xs={24} sm={24} md={0} lg={0} xl={0} style={{ width: "100%", height: "100%", cursor: "pointer" }} onClick={() => { startVideo(); setShowVideo(true) }}>
                    <Row style={{ height: "200px" }}>
                        <div className='container' style={{ width: "100%", height: "100%", position: "relative" }}>
                            <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "end", position: "absolute", right: "0px" }}>
                                <img src="./images/ourservice_btn.png" style={{
                                    width: "100%", height: "100%", objectFit: "cover",
                                    position: "absolute",
                                    right: 0
                                }} />
                            </div>
                            <div style={{ width: "100%", height: "100%", position: "absolute", left: 0 }}>
                                <div style={{ width: "100%", height: "100%", position: "relative" }}>
                                    <div className='OurServiceHeaderBtn' style={{ marginLeft: "20px", marginRight: "20px", width: "auto" }}>LED DISPLAY DESIGN SOLUTION</div>
                                    <div className='OurServiceBodyBtn' style={{ marginLeft: "20px", marginRight: "20px", width: "auto" }}>PushCodex provides a diversity of LED display technology for various multimedia applications.</div>
                                </div>
                            </div>
                        </div>
                    </Row>

                    <Row style={{ paddingTop: "6px" }} gutter={12}>

                        {
                            serviceData.map((data, index) => {
                                return (
                                    <Col key={`service_mobile_${index}`} xs={24} sm={8} md={8} lg={8} xl={8} style={{ width: "100%", height: "520px", backgroundColor: "white" }}>
                                        <ServicePreviewModule {...data}></ServicePreviewModule>
                                    </Col>
                                )
                            })
                        }

                    </Row>
                </Col>

            </Row >
            <Modal
                title={null}
                visible={showVideo}
                width={Math.min(1080, windowsWidth * 0.8)}
                footer={null}
                onCancel={() => { setShowVideo(false) }}

                centered
                afterClose={stopVideo}
                bodyStyle={{ padding: 0 }}
            >
                <Player
                    preload="auto"
                    autoPlay
                    fluid
                    ref={playerRef}
                >
                    <source
                        src="./videos/codex_showreel.mp4"
                        type="video/mp4"
                    />
                </Player>
            </Modal>
        </div >
    )
}

export default OurServiice;