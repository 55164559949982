import './HeaderMenu.css';
import { Row, Col, Divider } from 'antd';
import 'antd/dist/antd.css';
import { MenuOutlined } from '@ant-design/icons';
import { Drawer, Button, Radio, Space } from 'antd';
import DrawerMenu from './DrawerMenu';
import React, { useRef, useState, useEffect } from 'react';
import { DownCircleOutlined, DownOutlined } from '@ant-design/icons';
import { gsap } from "gsap";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";


function HeaderMenu() {

  const [pageHeight, setPageHeight] = useState(850);

  const particlesInit = async (main) => {

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(main);
  };

  const onResize = () => {

    setPageHeight(window.innerHeight)
  }
  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
  }, [])

  const FuturisticParticles = () => (
    <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      height="800px"
      options={{
        fpsLimit: 120,
        interactivity: {
          events: {
            onClick: {
              enable: false,
              mode: "push",
            },
            onHover: {
              enable: true,
              mode: "grab",
            },
            resize: true,
          },
          modes: {
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        fullScreen: {
          enable: false,
          zIndex: 1
        },
        particles: {
          color: {
            value: "#ffffff",
          },
          links: {
            color: "#ffffff",
            distance: 300,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: "none",
            enable: true,
            outModes: {
              default: "bounce",
            },
            random: false,
            speed: 0.8,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 500,
            },
            value: 12,
          },
          opacity: {
            value: 0.2,
          },
          shape: {
            type: "circle",
          },
          size: {
            value: { min: 1, max: 5 },
          },
        },
        detectRetina: true,
      }}
    />
  )

  const particlesLoaded = (container) => {
    // console.log(container);
  };

  const [visible, setVisible] = useState(false);
  var drawerRef = useRef();

  const downArrowRef = useRef();
  // wait until DOM has been rendered
  useEffect(() => {
    gsap.to(downArrowRef.current, {
      duration: 0.5,
      y: "20", yoyo: true,
      repeat: -1,
      ease: "easeInOut"
    });
  }, []);
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const closeDrawer = async () => {
    setVisible(false);
    await setTimeout(1000);

  }
  const scrollTo = (id) => {
    var elmntToView = document.getElementById(id);
    elmntToView.scrollIntoView({ behavior: "smooth" });
  }

  const menuButtonStyle = { color: "white", fontFamily: "Kanit", fontSize: "18px" };

  return (
    <div id="section_home" style={{ width: "100%", display: "inline-block", position: "relative" }}>
      <Row className="Header-menu" style={{ width: "100%", height: "80px", position: "absolute", top: 0, left: 0, zIndex: 100 }} justify="space-between" align="middle">

        <Col xs={0} sm={0} md={0} lg={20} xl={18} style={{ height: "100%", margin: "30px 0", paddingLeft: "44px" }}>
          <Row gutter={20} className='Header-text' style={{ width: "100%", height: "100%" }} align="middle">
            <Col style={{ width: "280px" }}><img src="./images/codex_logo.png" /></Col>
            <Col ><Button type="text" style={menuButtonStyle} className="hover-underline-animation" onClick={() => {
              scrollTo("section_home");
            }}>HOME</Button></Col>
            <Col ><Button type="text" style={menuButtonStyle} className="hover-underline-animation" onClick={() => {
              scrollTo("section_aboutus");
            }}>ABOUT US</Button></Col>
            <Col ><Button type="text" style={menuButtonStyle} className="hover-underline-animation" onClick={() => {
              scrollTo("section_service");
            }}>SERVICE</Button></Col>
            <Col ><Button type="text" style={menuButtonStyle} className="hover-underline-animation" onClick={() => {
              scrollTo("section_gallery");
            }}>GALLERY</Button></Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={0} xl={0} style={{ height: "100%" }}>
          <Row style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }} justify="center" align="middle">
            <div style={{ display: "inline-block", textAlign: "center", margin: "40px 14px" }}>
              <img style={{ height: "50px" }} src='./images/codex_logo.png' />
            </div>
            <div align="middle" style={{ display: "inline-block", position: "absolute", right: "0px", top: "0px", margin: "14px", display: "flex", justifyContent: "center" }}>
              <Button type="link" onClick={showDrawer} style={{ color: "#fff" }} size="middle" icon={<MenuOutlined style={{ fontSize: '50px' }} />} />
            </div>
          </Row>
        </Col>
        <Col xs={0} sm={0} md={0} lg={3} xl={4} style={{ width: "80px", textAlign: "right", paddingRight: "40px" }} align="middle">
          <Button type="text" style={menuButtonStyle} className="hover-underline-animation" onClick={() => {
            scrollTo("section_contact");
          }}>CONTACT US</Button>
        </Col>
      </Row>
      <Row style={{ width: "100%", zIndex: 1 }}>

        <Col xs={0} sm={0} md={24} lg={24}>
          <img src="./images/coverpage_01.png" alt="Header Cover" style={{ objectFit: "cover", width: "100%", maxHeight: `${pageHeight}px` }}></img>

          <div style={{ position: "absolute", width: "100%", height: "100%", top: 0, left: 0 }}>

            <FuturisticParticles />
          </div>
        </Col>
        <Col xs={24} sm={24} md={0} lg={0}>
          <img src="./images/coverpage_01.png" alt="Header Cover" style={{ objectFit: "cover", width: "100%" }}></img>

          <div style={{ position: "absolute", width: "100%", height: "100%", top: 0, left: 0 }}>

            <FuturisticParticles />
          </div>
        </Col>
      </Row>
      <Drawer
        title=""
        placement={"right"}
        closable={true}
        onClose={onClose}
        visible={visible}
        drawerStyle={{ color: "white" }}
        ref={drawerRef}
      >
        <Row className='Header-text' style={{ width: "100%" }} >
          <Col span={24}><Button type="text" style={{ fontSize: "18px", color: "#24442F", margin: "10px" }} onClick={() => {
            closeDrawer().then(() => scrollTo("section_home"));
          }}>HOME</Button></Col>
          <Col span={24}><Button type="text" style={{ fontSize: "18px", color: "#24442F", margin: "10px" }} onClick={() => {
            closeDrawer().then(() => scrollTo("section_aboutus"));
          }}>ABOUT US</Button></Col>
          <Col span={24}><Button type="text" style={{ fontSize: "18px", color: "#24442F", margin: "10px" }} onClick={() => {
            closeDrawer().then(() => scrollTo("section_service"));
          }}>SERVICE</Button></Col>
          <Col span={24}><Button type="text" style={{ fontSize: "18px", color: "#24442F", margin: "10px" }} onClick={() => {
            closeDrawer().then(() => scrollTo("section_gallery"));
          }}>GALLERY</Button></Col>
          <Col span={24}><Button type="text" style={{ fontSize: "18px", color: "#24442F", margin: "10px" }} onClick={() => {
            closeDrawer().then(() => scrollTo("section_contact"));
          }}>CONTACT US</Button></Col>
        </Row>
      </Drawer>

      <div style={{ position: "absolute", bottom: "4em", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <DownOutlined onClick={() => {
          scrollTo("section_aboutus");
        }} ref={downArrowRef} style={{ fontSize: '30px', color: "white", cursor: "pointer" }} size='large' />
      </div>
    </div>
  );
}

export default HeaderMenu;