import { Row, Col, Divider, Image } from 'antd';
import { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import './ContractUs.css';
import { Button, Modal } from 'antd';

function ContractUs() {

    const [job1Visible, setJob1Visible] = useState(false);
    const [job2Visible, setJob2Visible] = useState(false);

    return (
        <div id="section_contact" style={{ marginTop: "30px" }}>
            <Row style={{ width: "100%" }}>
                <Col xs={0} sm={0} md={0} lg={24} xl={24} >
                    <div className='container' style={{ position: "relative", width: "100%", height: "300px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0186ce" }}>
                        <img src="./images/contractus_01.png" style={{ width: "100%", objectFit: "cover", position: "absolute", height: "100%", left: 0, opacity: 0.4 }} />
                        <div style={{ width: "100%", marginLeft: "60px", display: "inline-block", left: 0, textAlign: "left", zIndex: 200 }}>
                            <div className='iHeaderBtn'>CONTACT US</div>
                            <div className='iBodyBtn appBodyText' style={{ wordWrap: "break-word" }} >PUSH CODEX COMPANY LIMITED <br />200 Khwaeng Phlabphla, Khet Wang Thonglang, Krung Thep Maha Nakhon 10310</div>
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={0} xl={0} >
                    <div className='container' style={{ position: "relative", width: "100%", height: "300px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#0186ce" }}>
                        <img src="./images/contractus_01.png" style={{ width: "100%", objectFit: "cover", position: "absolute", height: "100%", left: 0, opacity: 0.4 }} />
                        <div style={{ width: "100%", padding: "20px", display: "inline-block", left: 0, textAlign: "left", zIndex: 200 }}>
                            <div className='iHeaderBtn'>CONTACT US</div>
                            <div className='iBodyBtn appBodyText' style={{ wordWrap: "break-word" }} >PUSH CODEX COMPANY LIMITED <br />200 Khwaeng Phlabphla, Khet Wang Thonglang, Krung Thep Maha Nakhon 10310</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row style={{ width: "100%", margin: "1px 0" }} >
                <Col xs={24} sm={24} md={24} lg={12} xl={12} >
                    <Row style={{ backgroundColor: "#0187CE", width: "100%", height: "120px" }} wrap={false}>
                        <Col flex="120px" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img src='./images/contractus_icon_01.png' style={{ height: "65px", width: "65px", objectFit: "contain" }} />
                        </Col>
                        <Col flex="auto" style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                            {/* <div className='TextBGGGtn'>Tel. (+66) 2 438 8425 or (+66) 81 173 1683 <br />Opening hours (10:00AM-18.30PM)</div> */}
                            <div className='TextBGGGtn'><span style={{ fontSize: "22px" }}>Tel. (+66) 2 438 8425</span><br /><span style={{ opacity: 0.5 }}>Opening hours (10:00AM-18.00PM)</span></div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={0} sm={0} md={0} lg={12} xl={12} style={{ borderLeft: "1px solid #ffffff" }}>
                    <Row style={{ backgroundColor: "#0187CE", width: "100%", height: "120px" }} wrap={false}>
                        <Col flex="120px" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => {
                            // window.open('mailto:codex@push.co.th');
                        }}>
                            <img src='./images/contractus_icon_02.png' style={{ height: "65px", width: "65px", objectFit: "contain" }} />
                        </Col>
                        <Col flex="auto" style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                            <div className='TextBGGGtn'><span style={{ fontSize: "25px" }}>codex@push.co.th</span></div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={0} xl={0} style={{ borderTop: "1px solid #ffffff" }}>
                    <Row style={{ backgroundColor: "#0187CE", width: "100%", height: "120px" }} wrap={false}>
                        <Col flex="120px" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img src='./images/contractus_icon_02.png' style={{ height: "65px", width: "65px", objectFit: "contain" }} />
                        </Col>
                        <Col flex="auto" style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                            <div className='TextBGGGtn'><span style={{ fontSize: "25px" }}>codex@push.co.th</span></div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <img style={{ width: "100%", height: "300px", objectFit: "cover" }} src='./images/contractus_02.png' />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    {/* <div className='container' style={{ width: "100%", height: "100%", objectFit: "cover" }} >
                        <img src='./images/contractus_03.png' style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                        <div className='RecrutHeader'>RECRUIT</div>
                        <div className='RecrutSubHeader'>FULLTIME</div>
                        <div className='RecrutBody'>Upscale shopping is made easy with our online directory. Get</div>
                        <Button className='TextBtn' type='ghost'>SEE MORE</Button>
                    </div> */}

                    <div style={{ width: "100%", height: "300px", background: "URL(./images/contractus_03.png)", backgroundSize: "cover", display: "flex", alignItems: "center", paddingLeft: "30px" }} >
                        <div style={{ display: "inline-block", width: "100%" }}>
                            <div className='ContatctHeaderBtn'>WE'RE RECRUITING</div>
                            <div onClick={() => { setJob1Visible(true) }} style={{ cursor: "pointer", marginTop: "10px" }}>
                                <div>
                                    <span className='ContatctBodyBtn'>1. Back-end Developer </span><span style={{ color: "gold" }}><b>[Click for Detail]</b></span>
                                </div>
                                <div className='ContatctRecruitDetail'>Experienced with NodeJS, AWS, SocketIO, Restful API</div>
                            </div>
                            <div onClick={() => { setJob2Visible(true) }} style={{ cursor: "pointer", marginTop: "10px" }}>
                                <div>
                                    <span className='ContatctBodyBtn'>2. Front-end Developer </span><span style={{ color: "gold" }}><b>[Click for Detail]</b></span>
                                </div>
                                <div className='ContatctRecruitDetail'>Familiar with ReactJS, HTML, CSS, Javascript</div>
                            </div>
                            {/* <Button className='ContatctServiceGalleryTextBtn' type='ghost'>SEE MORE</Button> */}
                            <div className='ContatctRecruitDetail' style={{ marginTop: "40px", marginLeft: "3px" }}>* If you are interested, please email us your resume + specify your expected salary at codex@push.co.th</div>
                        </div >
                    </div >
                </Col >
            </Row >
            <Modal
                title="Back-end Developer (Fulltime)"
                centered
                visible={job1Visible}
                onOk={() => setJob1Visible(false)}
                onCancel={() => setJob1Visible(false)}
                width={1000}
            >
                <p>
                    PushCodeX is a growing software company that provides creative solutions for our clients. We have been working closely, throughout several projects, with large companies such as the Central Group, etc. We are looking for an eager-to-learn with open-minded front-end developer
                    to join our office at Town-in-Town.</p>

                <h4>Job Description:</h4>
                <ul>
                    <li>Responsible for backend developments</li>
                    <li>Support team with API development</li>
                    <li>Estimate and design database and server architecture for new projects</li>
                </ul>
                <br />
                <br />
                <h4>Requirements:</h4>
                <ul>
                    <li>Bachelor's Degree or higher in Computer Science (or related fields)</li>
                    <li>Minimum 2 years experience with back-end development</li>
                    <li>Familiar with server/database design, CMS development, Restful/Socket APIs development</li>
                    <li>Experienced with Cloud AWS, Digital Ocean, Google Cloud Platform</li>
                    <li>Experienced with SQL, JavaScript, JSON</li>
                    <li>Open-minded, team player and self-managing</li>
                </ul>
                <br />
                <br />
                <h4>Nice to have:</h4>
                <ul>
                    <li>Experienced with GIT</li>
                    <li>Experienced with HTML, CSS, React.JS</li>
                    <li>Experienced with Firebase/SocketIO</li>
                    <li>Experienced with NodeJS</li>
                    <li>Experienced with Docker</li>
                </ul>
                <br />
                <br />
                <h4>Benefits:</h4>
                <ul>
                    {/* <li>Salary(Baht) : 30,000 - 45,000</li> */}
                    <li>Salary</li>
                    <li>Annual Bonus</li>
                    <li>Social Welfare (ประกันสังคม)</li>
                </ul>
                <br />
                <br />

            </Modal>
            <Modal
                title="Front-end Developer (Fulltime)"
                centered
                visible={job2Visible}
                onOk={() => setJob2Visible(false)}
                onCancel={() => setJob2Visible(false)}
                width={1000}
            >
                <p>
                    PushCodeX is a growing software company that provides creative solutions for our clients. We have been working closely, throughout several projects, with large companies such as the Central Group, etc. We are looking for an eager-to-learn with open-minded front-end developer
                    to join our office at Town-in-Town.</p>

                <h4>Job Description:</h4>
                <ul>
                    <li>Build applications for the web or mobile platform.</li>
                    <li>Work on bug fixing and improving application performance.</li>
                    <li>Continuously discover,evaluate,and implement new technologies to maximize development.</li>
                </ul>
                <br />
                <br />
                <h4>Requirements:</h4>
                <ul>
                    <li>Male or Female, under the age of 30 years old</li>
                    <li>Bachelor's Degree or higher in Computer Science (or related fields)</li>
                    <li>Open-minded, team player and self-managing</li>
                    <li>0-2 years experience with front-end development such as web and mobile applications</li>
                    <li>Experienced with HTML5 JSON JavaScript CSS3 and RESTful API</li>
                </ul>
                <br />
                <br />
                <h4>Nice to have:</h4>
                <ul>
                    <li>Experienced with GIT</li>
                    <li>Experienced with React.JS</li>
                    <li>Experienced with Firebase</li>
                    <li>Experienced with Server-side programming such as PHP NodeJS</li>
                    <li>Knows how to write good front end test cases</li>
                    <li>Experienced with Redux</li>
                </ul>
                <br />
                <br />
                <h4>Benefits:</h4>
                <ul>
                    {/* <li>Salary(Baht) : 20,000 - 25,000</li> */}
                    <li>Salary</li>
                    <li>Annual Bonus</li>
                    <li>Social Welfare (ประกันสังคม)</li>
                </ul>
                <br />
                <br />

            </Modal>
        </div >
    )
}

export default ContractUs;