import { Row, Col, Divider, Image } from 'antd';
import { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import './Footer.css';
import { Button } from 'antd';

function Footer() {

    const scrollTo = (id) => {
        var elmntToView = document.getElementById(id);
        elmntToView.scrollIntoView({ behavior: "smooth" });
    }

    return (
        <div>
            <Row>
                <div className='BlueLine'></div>
            </Row>
            {/* <Row style={{ backgroundColor: "#36454f", padding: "40px 0" }}> */}
            <Row style={{ backgroundColor: "#1e1c1c", padding: "40px 0" }}>
                <Col xs={24} sm={24} md={18} lg={20} xl={20} style={{ display: "flex", alignItems: "center", paddingLeft: "40px" }}>
                    <div className='FooterText'>
                        PUSH CODEX COMPANY LIMITED
                        <br />200 Khwaeng Phlabphla, Khet Wang Thonglang, Krung Thep Maha Nakhon 10310<br /><br />
                        Tel: (+66) 02 438 8425 <br />Opening hours (10:00AM-18.00PM)
                        <br />Email: codex@push.co.th
                    </div>
                </Col>
                <Col xs={24} sm={24} md={6} lg={4} xl={4} style={{ paddingRight: "40px", margin: "40px 0" }}>
                    <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }} onClick={() => {
                        scrollTo("section_home");
                    }}>
                        <img src='./images/codex_logo.png' style={{ maxWidth: "180px", objectFit: "contain" }} />
                    </div>
                </Col>
            </Row>
        </div >
    )
}

export default Footer;