import LightGallery from 'lightgallery/react';
import { useState, useEffect } from 'react';
import { Carousel, Image, Row, Col, Divider, List } from 'antd';
import './Gallery.css';
import { LeftOutlined, RightOutlined, LeftCircleFilled, RightCircleFilled } from "@ant-design/icons";

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const contentStyle = {
    // background: 'blue',
};

function Gallery() {
    const [galleryPageCount, setGalleryPageCount] = useState(1);

    const images = [
        "./images/gallery_01.png"
        , "./images/gallery_02.png"
        , "./images/gallery_03.png"
        , "./images/gallery_04.png"
        , "./images/gallery_05.png"
        , "./images/gallery_06.png"
    ]
    const onResize = () => {

        const count = Math.max(1, Math.floor((window.innerWidth - 200) / 290));

        setGalleryPageCount(count)
    }

    const getImages = () => {
        const result = [];

        images.forEach((img, index) => {
            if (index % galleryPageCount == 0) {
                result.push(<div key={`gallery_page_index}`} style={{ display: "inline-block" }}>
                    {
                        images.map((img1, index1) => {
                            if (index1 >= index && index1 < index + galleryPageCount) {
                                return <Image key={`gallery_${index}_${index1}`} src={img1} style={{ width: "200px", margin: "5px" }}></Image>
                            }

                        })
                    }
                </div>)
            }
        })

        return result;
    }

    useEffect(() => {
        onResize();
        window.addEventListener('resize', onResize);
    }, [])

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <LeftCircleFilled {...props} className="galleryArrow" />
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <RightCircleFilled {...props} className="galleryArrow" />
    );


    return (
        <div id="section_gallery" className="App" style={{ marginTop: "0px", background: "white", padding: "20px" }}>
            <Row style={{ width: "100%", overflow: "hidden" }}>
                <Col xs={{ offset: 0 }} sm={{ offset: 0 }} md={{ offset: 2 }} lg={{ offset: 3 }} xl={{ offset: 3 }} xxl={{ offset: 3 }} style={{ overflow: "hidden" }}>
                    <div className='Gallery-Header'>GALLERY</div>
                    <div className='Gallery-Body'>Our products and services</div>
                </Col>
            </Row>
            <Image.PreviewGroup style={{}}>
                <Carousel infinite={true} autoplay={true} autoplaySpeed={5000} speed={1800} arrows prevArrow={<SlickArrowLeft />} nextArrow={<SlickArrowRight />} style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "0 20px" }}>
                    {getImages()}
                </Carousel>
            </Image.PreviewGroup>
        </div>
    );
}

export default Gallery;