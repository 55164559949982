import { Row, Col, Divider, Image } from 'antd';
import { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import './ServiceGallery.css';
import { Button } from 'antd';

function ServiceGallery() {
    return (
        <div>
            <Row style={{ width: "100%" }}>
                <Col xs={0} sm={0} md={0} lg={24} xl={24}>
                    <div className='container' style={{ position: "relative", width: "100%", height: "350px", display: "flex", alignItems: "center" }}>
                        <img src="./images/ourservice_02.png" style={{ width: "100%", objectFit: "cover", position: "absolute", height: "100%", left: 0 }} />
                        <div style={{ width: "100%", display: "inline-block", position: "absolute", left: 0, textAlign: "left", padding: "60px" }}>
                            <div className='HeaderBtn'>DIGITAL SIGNAGE SOLUTION</div>
                            <div className='BodyBtn'>Our cloud-based digital signage solution offers a state-of-the-art digital signage platform that allow users to design, manage and maintain their digital content on all digital signage displays</div>
                            {/* <Button className='ServiceGalleryTextBtn' type='ghost'>SEE MORE</Button> */}
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={0} xl={0} >
                    <div className='container' style={{ position: "relative", width: "100%", height: "350px", display: "flex", alignItems: "center" }}>
                        <img src="./images/ourservice_02.png" style={{ width: "100%", objectFit: "cover", position: "absolute", height: "100%", left: 0 }} />
                        <div style={{ width: "100%", display: "inline-block", position: "absolute", left: 0, textAlign: "left", padding: "20px" }}>
                            <div className='HeaderBtn'>DIGITAL SIGNAGE SOLUTION</div>
                            <div className='BodyBtn'>Our cloud-based digital signage solution offers a state-of-the-art digital signage platform that allow users to design, manage and maintain their digital content on all digital signage displays</div>
                            {/* <Button className='ServiceGalleryTextBtn' type='ghost'>SEE MORE</Button> */}
                        </div>
                    </div>
                </Col>
            </Row>
        </div >
    )
}

export default ServiceGallery;