import { Row, Col, Divider, Image } from 'antd';
import { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import './OurPartner.css';
import { Button } from 'antd';

function OurPartner() {
    return (
        <div style={{ width: "100%", marginBottom: "80px", padding: "20px" }}>
            <Row style={{ width: "100%" }} justify="center">
                <Col>
                    <div className='OurPartner-Header'>OUR CLIENTS</div>
                    <div className='OurPartner-Body'>Here is a list of some of our current and past clients</div>
                </Col>
            </Row>
            <Row style={{ width: "100%" }} justify="center">
                <Col xs={8} sm={6} md={5} lg={4} xl={4} xxl={3} >
                    <img src='./images/ourpartner_01.png' style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                </Col>
                <Col xs={8} sm={6} md={5} lg={4} xl={4} xxl={3}>
                    <img src='./images/ourpartner_02.png' style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                </Col>
                <Col xs={8} sm={6} md={5} lg={4} xl={4} xxl={3}>
                    <img src='./images/ourpartner_03.png' style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                </Col>
                <Col xs={8} sm={6} md={5} lg={4} xl={4} xxl={3}>
                    <img src='./images/ourpartner_04.png' style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                </Col>
                <Col xs={8} sm={6} md={5} lg={4} xl={4} xxl={3}>
                    <img src='./images/ourpartner_05.png' style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                </Col>
                <Col xs={8} sm={6} md={5} lg={4} xl={4} xxl={3}>
                    <img src='./images/ourpartner_06.png' style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                </Col>
            </Row>
        </div>
    )
}

export default OurPartner;